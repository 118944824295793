import { Button } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import media from 'shared/helpers/media';
import PlusOutlined from 'shared/images/landing/icon_add_24.svg';

export const DesktopAddButton = styled(Button)`
  &.ant-btn.ant-btn-sm {
    padding: 12px 16px;
    border-radius: 20px;
    color: white;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Manrope', serif;
    font-size: 14px;

    svg {
      min-width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }

  margin-left: auto;
  margin-right: 20px;
  position: relative;
  display: none;

  ${media.tablet`
     display: block;
  `};
`;
export const AddAccountButton = () => {
  return (
    <>
      <Link to="/accounts/new">
        <DesktopAddButton type="primary" icon={<PlusOutlined />}>
          Add account
        </DesktopAddButton>
      </Link>
    </>
  );
};
