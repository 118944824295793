import { Button, Drawer } from 'antd';
import { config } from 'config';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { LogoutButton } from 'shared/components/common/LogoutButton';
import LogoTrackStats from 'shared/images/FullLogoWhiteStroke.svg';
import IconClose from 'shared/images/icon_close_24_blue.svg';
import Discord from 'shared/images/social/discrord.svg';
import Medium from 'shared/images/social/medium.svg';
import Twitter from 'shared/images/social/twitter.svg';
import { SocialButton } from 'shared/layouts/Header/Header';
import { userSelector } from 'shared/recoil/selectors/user';

export const MobileDrawer = styled(Drawer)`
  width: 100%;

  .ant-drawer-wrapper-body {
    background: #ffffff;
  }

  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    padding: 0 0 24px 0;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 35px;
  //gap: 35px;
  justify-content: center;
`;

const IconsWrapper = styled.div`
  margin: auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0 48px;
  height: 100%;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto auto 0 auto;
  align-items: center;
`;

const DescriptionText = styled.span`
  font-size: 15px;
  color: #a0a0a0;
  text-align: center;
  margin: 31.5px auto 24px;
`;

const SocialButtons = styled.ul`
  display: flex;
  margin: 0;
  list-style: none;
  padding: 0;

  li a.ant-btn-sm {
    margin-right: 16px;
  }

  li {
    &:last-child a {
      margin: 0;
    }
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 27px;
  border-bottom: 1px solid #e8ebfd;
`;

const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 48px;
  margin: auto;
  width: 100%;
  height: 100%;
`;

const DrawerButton = styled(Button)`
  &.ant-btn.ant-btn-sm {
    font-size: 24px;
    color: #213df0;
    width: 100%;
  }
`;

interface Props {
  hideMenu: () => void;
  menuOpened: boolean;
  isAccounts: boolean;
  isNewAccountPage: boolean;
  isDemoPage: boolean;
  isPortfolio: boolean;
  hasAccountsData: boolean;
}

export const CustomDrawer = ({
  hideMenu,
  menuOpened,
  isAccounts,
  isDemoPage,
  isNewAccountPage,
  isPortfolio,
  hasAccountsData,
}: Props) => {
  const navigate = useNavigate();
  const user = useRecoilValue(userSelector);

  return (
    <MobileDrawer
      placement="left"
      width="100%"
      open={menuOpened}
      closable={false}
      closeIcon={<IconClose />}
    >
      <Header>
        <span onClick={() => navigate('/')}>
          <LogoTrackStats />
        </span>
        <span onClick={hideMenu}>
          <IconClose />
        </span>
      </Header>
      <Main>
        {!isPortfolio && hasAccountsData ? (
          <DrawerButton type="link" onClick={() => navigate('/portfolio')}>
            My Portfolio
          </DrawerButton>
        ) : null}
        {!isNewAccountPage && !hasAccountsData ? (
          <DrawerButton type="link" onClick={() => navigate('/accounts/new')}>
            {isAccounts ? 'Add account' : 'Create Portfolio'}
          </DrawerButton>
        ) : null}
        {isDemoPage || isAccounts || user?.email ? null : (
          <DrawerButton type="link" onClick={() => navigate('/demo')}>
            Try Demo
          </DrawerButton>
        )}
        {!user?.email ? (
          <DrawerButton type="link" href={`${config.auth.basePath}/ui/login?flow`}>
            Sign In
          </DrawerButton>
        ) : null}
      </Main>
      <IconsWrapper>
        <Footer>
          {user?.email ? (
            <Content>
              <LogoutButton />
            </Content>
          ) : null}
          <DescriptionText>
            Make a tracker that you have always wished for, together with us.
          </DescriptionText>
          <SocialButtons>
            <li>
              <SocialButton target="_blank" href="https://discord.gg/54nwYY4g8N">
                <Discord />
              </SocialButton>
            </li>
            <li>
              <SocialButton target="_blank" href="https://twitter.com/trackstats_io">
                <Twitter />
              </SocialButton>
            </li>
            <li>
              <SocialButton target="_blank" href="https://medium.com/@trackstats">
                <Medium />
              </SocialButton>
            </li>
          </SocialButtons>
        </Footer>
      </IconsWrapper>
    </MobileDrawer>
  );
};
