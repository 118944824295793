import styled from 'styled-components';

import { WHITE } from 'shared/helpers/styles/variables';
import MenuOutlined from 'shared/images/icon_burger_24.svg';

export const Wrap = styled.div`
  background-color: ${({ $theme }: { $theme: string }) =>
    $theme === 'white' ? '#ffffff' : 'inherit'};
  width: 100%;
`;

export const MobileMenu = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 10px;
`;

export const Menu = styled.div`
  display: none;
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 12px 10px;

  @media (max-width: 696px) {
    display: none;
  }
`;

export const LogoIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
`;

export const OpenMenuButton = styled(MenuOutlined)<{ $theme: string }>`
  margin-left: 12px;
  //width: 80px;
  color: ${({ $theme }) => ($theme === 'white' ? 'inherit' : WHITE)};
`;
