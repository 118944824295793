import { CaretDownOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Button, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DarkModeSwitch } from 'react-toggle-dark-mode';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { GET_CRYPTO_TICKERS, GET_CURRENCY } from 'shared/api/currency';
import { useNoAuthAccounts } from 'shared/api/useNoAuthAccounts';
import { GET_ACCOUNTS } from 'shared/components/Accounts/Accounts.graphql';
import { LogoutButton } from 'shared/components/common/LogoutButton';
import { useWindowSize } from 'shared/hooks/useWindowSize';
import BackIcon from 'shared/images/icons/back-arrow.svg';
import PortfolioIcon from 'shared/images/landing/portfolio.svg';
import LogoImg from 'shared/images/LogoImg.svg';
import Discord from 'shared/images/social/discrord.svg';
import Medium from 'shared/images/social/medium.svg';
import Twitter from 'shared/images/social/twitter.svg';
import AppLogo from 'shared/images/TRACKSTATS.svg';
import { AddAccountButton } from 'shared/layouts/Header/AddAccountButton';
import {
  cryptoTickersAtom,
  currencyAtom,
  selectedCurrencyAtom,
} from 'shared/recoil/atoms/currency';
import { themeAtom } from 'shared/recoil/atoms/theme';
import { userSelector } from 'shared/recoil/selectors/user';
import {
  coinGeckoIds,
  convertRatesToUSD,
  cryptocurrencies,
  currencyNames,
  suggestedCurrency,
} from 'shared/utils/currencyConverter';

import { CustomDrawer } from './Drawer';
import { OpenMenuButton } from './Header.styled';

const CreatePortfolioButton = styled(Button)`
  display: none;

  &.ant-btn-lg {
    padding: 9px 16px;
    height: auto;
    border-radius: 20px;
    font-family: 'Manrope', serif;
    font-size: 14px;
    margin-right: 24px;
    @media (max-width: 426px) {
      margin-right: 12px;
    }
  }
`;

export const DemoModeButton = styled(Button)`
  &.ant-btn-sm {
    color: #213df0;
    padding: 10px 16px;
    border-radius: 20px;
    height: auto;
    font-size: 14px;
    font-family: 'Manrope', serif;
    margin-right: 24px;
    font-weight: 700;

    :last-child {
      margin: 0;
    }
  }
`;
const LogoutButtonWrapper = styled.div<{ showSignIn: boolean }>`
  display: ${props => (props.showSignIn ? 'inherit !important' : 'inherit')};
`;
export const SocialButton = styled(Button)`
  &.ant-btn-sm {
    color: #213df0;
    padding: 10px 16px;
    border-radius: 50%;
    font-size: 14px;
    margin-left: auto;
    margin-right: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;

    &:hover {
      background: #dce1fd;
    }
  }

  svg {
    width: 25px;
    min-width: 25px;
    height: 25px;
  }
`;

const SocialUl = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  @media (max-width: 425px) {
    display: none;
  }
`;
const LendingHeader = styled.header`
  position: sticky;
  top: -1px;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 24px 48px;
  background: rgb(255, 255, 255);

  ${OpenMenuButton} {
    @media (min-width: 696px) {
      display: none;
    }
  }

  ${SocialUl} {
    li {
      @media (max-width: 696px) {
        display: none;
      }

      &:last-child {
        display: flex;
        align-items: center;
      }
    }
  }

  &.header[stuck] {
    box-shadow: 0 0 16px rgba(45, 72, 241, 0.3);

    ${SocialUl} {
      li {
        @media (max-width: 696px) {
          &:first-child {
            display: flex;
          }
        }
      }
    }

    ${CreatePortfolioButton} {
      display: flex;
    }

    @media (max-width: 550px) {
      ${LogoutButtonWrapper} {
        display: none;
      }
    }
  }

  @media (max-width: 1024px) {
    padding: 24px 32px;
  }
  @media (max-width: 900px) {
    .appLogoText {
      display: none;
    }
  }

  @media (max-width: 426px) {
    padding: 24px 16px;
    .ant-btn-sm {
      margin-right: 16px;
    }
  }

  @media (max-width: 370px) {
    padding: 24px 5px;
    .ant-btn-sm {
      margin-right: 8px;
    }
  }
`;

export const PortfolioButton = styled(Button)`
  &.ant-btn.ant-btn-sm {
    padding: 12px 16px;
    border-radius: 20px;
    height: 40px;
    display: flex;
    align-items: center;
    margin: 0 24px 0 0;
    //justify-content: space-between;
    //width: 146px;

    svg {
      width: 22px;
      height: 22px;
      margin-right: 10px;
    }

    @media (max-width: 426px) {
      margin-right: 12px;
    }
  }
`;

export const OpenCurrencyButton = styled.div`
  cursor: pointer;
  margin-left: 10px;
`;

const ActionButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const ModalCurrencyTitle = styled.div`
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 16px;
`;

const ModalCurrencySuggestedTitle = styled.div`
  font-size: 14px;
  margin-bottom: 16px;
  font-weight: 600;
`;

const FiatTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 16px;
`;

const CurrencyList = styled.div`
  padding: 0;
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  list-style: none;
  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
  }
`;

export const CurrencyItem = styled.li<{ active: boolean }>`
  padding: 12px;
  border: ${props => (props.active ? '1px solid rgb(33, 103, 233)' : '1px solid transparent')};
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: transparent
}

;

&:hover {
  border: 1px solid rgb(33, 103, 233);
}
`;

const ModalCurrency = styled(Modal)`
  width: 100% !important;
  max-width: 900px;
`;

const CurrencyName = styled.span`
  color: rgb(100, 116, 139);
  margin-right: 8px;
`;

const SuggestedCurrenciesWrapper = styled.div`
  margin-bottom: 16px;
`;

const CurrencyDescription = styled.span``;

export const Header = () => {
  const [selectedCurrency, setSelectedCurrency] = useRecoilState(selectedCurrencyAtom);
  const [cryptoTickerList, setCryptoTickers] = useRecoilState(cryptoTickersAtom);
  const [_, setCurrency] = useRecoilState(currencyAtom);
  const { data: currencyList } = useQuery(GET_CURRENCY);
  const { data: cryptoTickers } = useQuery(GET_CRYPTO_TICKERS, {
    pollInterval: 2000,
    skip: cryptoTickerList?.length > 1,
  });
  const [isCurrencyModalOpen, setCurrencyModalOpen] = useState(false);
  const [isAccounts, setIsAccounts] = useState(true);
  const user = useRecoilValue(userSelector);
  const { noAuthTokens } = useNoAuthAccounts();
  const { data } = useQuery(GET_ACCOUNTS, {
    variables: {
      noAuthTokens,
    },
    initialFetchPolicy: 'cache-first', // DONT TOUCH!!!,
    fetchPolicy: 'cache-first', // DONT TOUCH!!!
    nextFetchPolicy: 'cache-first',
  });

  const { pathname, search } = useLocation();

  const params = new URLSearchParams(search);

  const accountParam = params.get('account');
  const status = params.get('status');

  const navigate = useNavigate();

  const { width } = useWindowSize();

  const [menuOpened, setMenuOpened] = useState(false);
  const showMenu = () => setMenuOpened(true);
  const hideMenu = () => setMenuOpened(false);
  const [appTheme, setTheme] = useRecoilState(themeAtom);
  const toggleDarkMode = (checked: boolean) => {
    setTheme(checked ? 'dark' : 'white');
  };

  const isAccountAddingProcess = accountParam?.length;
  const isNewAccountPage = pathname === '/accounts/new';
  const isDemoPage = pathname === '/demo';
  const isPortfolio = pathname === '/portfolio';
  const isLending = pathname === '/';

  useEffect(() => {
    if (cryptoTickers?.cryptoTickers) {
      setCryptoTickers(
        cryptoTickers.cryptoTickers.filter(({ name }: { name: string }) =>
          Object.keys(coinGeckoIds).includes(name),
        ),
      );
    }
  }, [cryptoTickers?.cryptoTickers?.length]);

  useEffect(() => {
    if (currencyList?.exchangeRates) {
      setCurrency(convertRatesToUSD(currencyList?.exchangeRates));
    }
  }, [currencyList?.exchangeRates]);

  useEffect(() => {
    if (menuOpened) {
      hideMenu();
    }
    const observer = new IntersectionObserver(
      ([e]) => e.target.toggleAttribute('stuck', e.intersectionRatio < 1),
      { threshold: [1] },
    );

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    observer.observe(document?.querySelector('header'));
  }, [pathname]);

  useEffect(() => {
    setIsAccounts(user?.email || noAuthTokens.length || data?.accounts?.length);
  }, [pathname, noAuthTokens, user, data, search]);

  return (
    <>
      <LendingHeader className="header">
        <>
          {isAccountAddingProcess && width < 769 ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <span onClick={() => navigate(-1)}>
                <BackIcon />
              </span>
              {status === 'done' ? (
                <Button type="link" onClick={() => navigate('/portfolio')}>
                  Done
                </Button>
              ) : null}
            </div>
          ) : (
            <>
              <LogoWrapper onClick={() => navigate('/')}>
                <LogoImg />
                <AppLogo className="appLogoText" style={{ marginLeft: 12 }} />
              </LogoWrapper>
              <ButtonsWrapper>
                <SocialUl>
                  <li>
                    {isDemoPage || isNewAccountPage || isAccounts ? null : (
                      <CreatePortfolioButton
                        size="large"
                        type="primary"
                        onClick={() => navigate('/accounts/new')}
                      >
                        {width > 330 ? 'Create portfolio' : 'Create'}
                      </CreatePortfolioButton>
                    )}
                  </li>
                  {isDemoPage || isNewAccountPage || isPortfolio ? null : (
                    <>
                      <li>
                        <SocialButton target="_blank" href="https://discord.gg/54nwYY4g8N">
                          <Discord />
                        </SocialButton>
                      </li>
                      <li>
                        <SocialButton target="_blank" href="https://twitter.com/trackstats_io">
                          <Twitter />
                        </SocialButton>
                      </li>
                      <li>
                        <SocialButton target="_blank" href="https://medium.com/@trackstats">
                          <Medium />
                        </SocialButton>
                      </li>
                    </>
                  )}
                  <li>
                    {isAccounts ? (
                      <>
                        {isPortfolio || isDemoPage ? (
                          <AddAccountButton />
                        ) : user?.email &&
                          !(noAuthTokens.length || data?.accounts?.length) ? null : (
                          <PortfolioButton
                            type="primary"
                            icon={<PortfolioIcon />}
                            onClick={() => navigate('/portfolio')}
                          >
                            {width < 375 ? 'Portfolio' : 'My Portfolio'}
                          </PortfolioButton>
                        )}

                        {((isDemoPage || isNewAccountPage || isPortfolio) && width < 391) ||
                        user?.email ? null : (
                          <LogoutButtonWrapper showSignIn={isNewAccountPage || isDemoPage}>
                            <LogoutButton />
                          </LogoutButtonWrapper>
                        )}
                        {user?.email && !isLending && width > 695 ? (
                          <LogoutButtonWrapper showSignIn={isNewAccountPage || isDemoPage}>
                            <LogoutButton />
                          </LogoutButtonWrapper>
                        ) : null}
                        {!isLending ? (
                          <OpenCurrencyButton
                            onClick={() => {
                              setCurrencyModalOpen(true);
                            }}
                          >
                            {selectedCurrency}
                            <CaretDownOutlined />
                          </OpenCurrencyButton>
                        ) : null}
                        {!isLending && width > 475 ? (
                          <DarkModeSwitch
                            style={{ marginLeft: 10 }}
                            checked={appTheme === 'dark'}
                            onChange={toggleDarkMode}
                          />
                        ) : null}
                      </>
                    ) : (
                      <ActionButtonsWrapper>
                        <LogoutButtonWrapper showSignIn={isNewAccountPage || isDemoPage}>
                          <LogoutButton />
                        </LogoutButtonWrapper>
                        {isDemoPage ? (
                          <CreatePortfolioButton
                            size="large"
                            type="primary"
                            onClick={() => navigate('/accounts/new')}
                            style={{ display: 'flex' }}
                          >
                            {width > 330 ? 'Create portfolio' : 'Create'}
                          </CreatePortfolioButton>
                        ) : (
                          <DemoModeButton onClick={() => navigate('/demo')}>
                            Try demo
                          </DemoModeButton>
                        )}
                      </ActionButtonsWrapper>
                    )}
                  </li>
                </SocialUl>
                <OpenMenuButton $theme={appTheme} onClick={showMenu} />
              </ButtonsWrapper>
            </>
          )}
        </>
      </LendingHeader>
      <CustomDrawer
        hasAccountsData={noAuthTokens.length || data?.accounts?.length}
        hideMenu={hideMenu}
        menuOpened={menuOpened}
        isAccounts={isAccounts}
        isNewAccountPage={isNewAccountPage}
        isDemoPage={isDemoPage}
        isPortfolio={isPortfolio}
      />
      <ModalCurrency
        footer={[]}
        open={isCurrencyModalOpen}
        onOk={() => {
          setCurrencyModalOpen(false);
        }}
        onCancel={() => {
          setCurrencyModalOpen(false);
        }}
      >
        <ModalCurrencyTitle>Select currency</ModalCurrencyTitle>

        <SuggestedCurrenciesWrapper>
          <ModalCurrencySuggestedTitle>Suggested Currencies</ModalCurrencySuggestedTitle>
          <CurrencyList>
            {suggestedCurrency.map(currency => (
              <CurrencyItem
                active={currency === selectedCurrency}
                key={currency}
                onClick={() => {
                  setSelectedCurrency(currency);
                  localStorage.setItem('selectedCurrency', currency);
                  setCurrencyModalOpen(false);
                }}
              >
                <CurrencyName>{currency}</CurrencyName>
                <CurrencyDescription>
                  {currencyNames?.[currency as keyof typeof currencyNames]}
                </CurrencyDescription>
              </CurrencyItem>
            ))}
          </CurrencyList>
        </SuggestedCurrenciesWrapper>
        <div>
          <FiatTitle>Fiat Currencies</FiatTitle>
          <CurrencyList>
            {currencyList?.exchangeRates
              ?.filter(({ currency }) => !suggestedCurrency.includes(currency))
              ?.map(({ currency }) => (
                <CurrencyItem
                  active={currency === selectedCurrency}
                  key={currency}
                  onClick={() => {
                    setSelectedCurrency(currency);
                    localStorage.setItem('selectedCurrency', currency);
                    setCurrencyModalOpen(false);
                  }}
                >
                  <CurrencyName>{currency}</CurrencyName>
                  <CurrencyDescription>
                    {currencyNames?.[currency as keyof typeof currencyNames]}
                  </CurrencyDescription>
                </CurrencyItem>
              ))}
          </CurrencyList>
        </div>
        <div>
          <FiatTitle>Cryptocurrencies</FiatTitle>
          <CurrencyList>
            {cryptocurrencies.map(currency => (
              <CurrencyItem
                active={currency === selectedCurrency}
                key={currency}
                onClick={() => {
                  setSelectedCurrency(currency);
                  localStorage.setItem('selectedCurrency', currency);
                  setCurrencyModalOpen(false);
                }}
              >
                <CurrencyName>{currency}</CurrencyName>
                <CurrencyDescription>
                  {currencyNames?.[currency as keyof typeof currencyNames]}
                </CurrencyDescription>
              </CurrencyItem>
            ))}
          </CurrencyList>
        </div>
      </ModalCurrency>
    </>
  );
};
