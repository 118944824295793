import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Modal } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { ACCOUNT_TOKENS, useNoAuthAccounts } from 'shared/api/useNoAuthAccounts';
import { DEMO_JWT, NO_AUTH_TOKEN } from 'shared/api/useNoAuthToken';
import {
  GET_ACCOUNTS,
  GET_USER,
  MERGE_ACCOUNTS,
} from 'shared/components/Accounts/Accounts.graphql';
import { GET_USER_FEATURES } from 'shared/components/Accounts/Users.graphql';
import { GET_TOKENS } from 'shared/components/Tokens/Token.graphql';
import { Header } from 'shared/layouts/Header/Header';
import { TokenListAtom } from 'shared/recoil/atoms/transactions';
import { userFeaturesAtom } from 'shared/recoil/atoms/userFeatures';
import { userSelector } from 'shared/recoil/selectors/user';
import routes from 'shared/routes';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
`;

export const CoingeckoWrapper = styled.div`
  padding: 20px;
`;
export const AppContent = () => {
  const { noAuthTokens } = useNoAuthAccounts();
  const [userFeatures, setUserFeatures] = useRecoilState(userFeaturesAtom);
  const [tokenListState, setTokenList] = useRecoilState(TokenListAtom);

  const user = useRecoilValue(userSelector);
  const [mergeAccounts] = useMutation(MERGE_ACCOUNTS);
  const { loading, data, refetch } = useQuery(GET_ACCOUNTS);
  const [loadUser] = useLazyQuery(GET_USER);
  const [modal, contextHolder] = Modal.useModal();
  const { pathname } = useLocation();

  const { data: tokensData } = useQuery(GET_TOKENS, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
    skip: !!tokenListState.length || !user,
  });

  const tokenList = tokensData?.tokens || [];

  useEffect(() => {
    if (tokenList.length && !tokenListState.length) {
      setTokenList(tokenList);
    }
  }, [tokenList.length]);

  const { data: userFeaturesData } = useQuery(GET_USER_FEATURES, {
    skip: !user,
    fetchPolicy: 'cache-first',
    variables: {
      uuid: user.id,
    },
  });

  useEffect(() => {
    if (userFeaturesData?.userFeatures?.features.length) {
      setUserFeatures(
        userFeaturesData.userFeatures?.features.map(({ featureFlag }) => ({
          ...featureFlag,
        })),
      );
    }
  }, [userFeaturesData]);

  useEffect(() => {
    const initMigrateModal = async () => {
      const DEMO_TOKEN = localStorage.getItem(DEMO_JWT);
      if (DEMO_TOKEN) {
        localStorage.setItem(NO_AUTH_TOKEN, DEMO_TOKEN);
        localStorage.removeItem(DEMO_JWT);
      }
      const noAuthUserId = localStorage.getItem(NO_AUTH_TOKEN);
      const res = await loadUser({ variables: { userId: user?.id } });

      if (
        (data?.accounts?.length && user?.mode !== 'demo') ||
        (res.data?.user?.isMerged && user?.mode !== 'demo')
      ) {
        localStorage.removeItem(ACCOUNT_TOKENS);
        localStorage.removeItem(NO_AUTH_TOKEN);
        return;
      }
      const {
        data: { accounts },
      } = await refetch();
      if (
        !res.data?.user?.isMerged &&
        !loading &&
        !accounts?.length &&
        user?.mode !== 'demo' &&
        localStorage.getItem(ACCOUNT_TOKENS)
      ) {
        modal.confirm({
          title: 'Do you want to merge your local data with the created account?',
          async onOk() {
            await mergeAccounts({
              variables: {
                noAuthTokens,
                demoAccountId: noAuthUserId,
                shouldMerge: true,
              },
            });
            localStorage.removeItem(ACCOUNT_TOKENS);
            localStorage.removeItem(NO_AUTH_TOKEN);
            window.location.reload();
          },
          async onCancel() {
            await mergeAccounts({
              variables: {
                noAuthTokens,
                demoAccountId: noAuthUserId,
                shouldMerge: false,
              },
            });
            localStorage.removeItem(ACCOUNT_TOKENS);
            localStorage.removeItem(NO_AUTH_TOKEN);
            window.location.reload();
          },
        });
      }
    };
    initMigrateModal();
  }, [user, data, noAuthTokens]);

  return (
    <>
      {contextHolder}
      <Wrap>
        <Header />
        <Content
          className="site-layout"
          style={{
            width: '100%',
            margin: '0 auto',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            flexGrow: '1',
          }}
        >
          <Routes>
            {routes.map(({ path, exact, component: Component, initialAction }, i) => (
              <Route key={i} {...{ path, exact }} element={<Component {...{ initialAction }} />} />
            ))}
          </Routes>
        </Content>
        {pathname === '/' ? null : (
          <CoingeckoWrapper>
            <span>Powered by</span>{' '}
            <a href="https://www.coingecko.com/en/api/documentation">CoinGecko API</a>
          </CoingeckoWrapper>
        )}
      </Wrap>
    </>
  );
};
